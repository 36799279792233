@import url("https://fonts.googleapis.com/css2?family=Oxanium&display=swap");

html {
  font-size: 62.5%; /* browser default 16px  62.5 */
  box-sizing: border-box;
}

body {
  font-size: 1.6rem Helvetica;
  height: 100vh;
  margin: 0;
  background-color: #f4f4f4 !important;
}
#root {
  height: 100%;
}
.full-width {
  width: 100%;
}
.mt-5 {
  margin-top: 0.5rem;
}
.link_cursor {
  cursor: pointer;
}
.link_cursor:hover {
  color: gold;
}
.grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  /* min-height: 100vh; */
  height: 100%;
}
.header {
  grid-area: header;
  background-color: rgb(160, 78, 140);
  color: whitesmoke;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.brand a {
  color: whitesmoke;
  font-size: 2.5rem;
  font-weight: bold;
  text-decoration: none;
}
.header-links a {
  color: whitesmoke;
  text-decoration: none;
  padding: 0.5rem;
}
.header-links a:hover {
  color: gold;
}

.main {
  grid-area: main;
}
.footer {
  grid-area: footer;
  background-color: rgb(160, 78, 140);
  color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* rich editor css */

.app .rich-editor {
  padding: 10rem;
}
.rich-editor .rdw-editor-main {
  background-color: #fff;
}
.rich-editor .DraftEditor-editorContainer {
  padding: 1rem;
}
.draft-editor-wrapper {
  border: 1px solid #ccc;
}

/* scrollbar css for firefox */
/* .MuiPaper-root{
  scrollbar-width: thin;
} */

/* -------------------------------- */
/* scrollbar design for chrome */
/* -------------------------------- */

/* .MuiPaper-root::-webkit-scrollbar
 {
   width: 4px;
   background-color: #F5F5F5;
   scrollbar-width: thin;
 }

 .MuiPaper-root::-webkit-scrollbar-track
 {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   border-radius: 10px;
   background-color: #F5F5F5;
 }
 

 
 .MuiPaper-root::-webkit-scrollbar-thumb
 {
   border-radius: 10px;
   box-shadow: inset 0 0 6px rgba(0,0,0,.3);
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
   background-color: #555;
 }  */

iframe::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
  scrollbar-width: thin;
}

iframe::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

iframe::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
/* -------------------------------- */
/* material ui stepper mobile progress bar component */
/* -------------------------------- */

.MuiMobileStepper-progress {
  width: 100% !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #28a745 !important;
}

/* ---------------- */
/* cta price card css */
/* ---------------- */

.pricingTable {
  text-align: center;
  border: 1px solid #dbdbdb;
  position: relative;
  overflow: hidden;
  padding-top: 48px;
}

.pricingTable .price-value {
  background: #fafafa;
  color: #666;
  font-weight: 900;
  padding: 15px 0;
}

.pricingTable .price-value span {
  color: #666;
  display: inline-block;
  font-size: 40px;
  font-weight: normal;
  line-height: normal;
}

.pricingTable .price-value small {
  font-size: 20px;
  position: relative;
  top: -15px;
  left: 0;
}

.pricingTable .price-value .subtitle {
  color: #82919f;
  display: block;
  font-size: 16px;
  font-weight: 100;
  font-style: italic;
}

.pricingTable .price-value-daily {
  background: #fafafa;
  color: #666;
  font-weight: 900;
  padding: 15px 10px;
}

.pricingTable .price-value-daily small {
  font-size: 20px;
  position: relative;
  top: -38px;
  left: 0;
}

.pricingTable .price-value-daily .subtitle {
  color: #82919f;
  display: block;
  font-size: 16px;
  font-weight: 100;
  font-style: italic;
}

.pricingTable .best-offer {
  background-color: #3498db;
  color: #fff;
  padding: 6px 50px;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  position: absolute;
  top: 20px;
  right: -55px;
  transform: rotate(45deg);
}

.pricingTable .pricingContent ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.pricingTable .pricingContent ul li {
  border-bottom: 1px solid #ededed;
  color: #9999a5;
  padding: 10px 0;
}

.pricingTable .pricingContent ul li:first-child {
  border-top: 1px solid #ededed;
}

.pricingTable .pricingTable-sign-up {
  padding: 25px 0;
}

.pricingTable .btn-block {
  background: #666;
  border-radius: 0px;
  border: 0px none;
  color: #fff;
  width: 50%;
  padding: 10px 5px;
  margin: 0 auto;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.pricingTable .btn-block:after {
  content: "\f090";
  font-family: "FontAwesome";
  padding-left: 10px;
  font-size: 15px;
}

.pricingTable .btn-block:hover {
  background: #282828;
  color: #fff;
}

@media screen and (max-width: 990px) {
  .pricingTable {
    margin-bottom: 20px;
  }
}

.paypal-button.paypal-button-layout-horizontal.paypal-button-number-multiple.paypal-button-number-0{
  width: 100% !important;
}
